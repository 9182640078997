<template>
    <div>
        <custom-vue-table class="pl-3 pr-3" title="Request List" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                          :extra-params="{ status }">
            <template #filters>
                <div class="btn-group ml-3">
                    <drop-btn text="Filters" icon="fa fa-filter" size="md" dropdown-width="30r">
                        <div class="p-3">
                            <validated-vue-select name="Status" :options="statusOptions"
                                                  label="Status"
                                                  v-model="status"/>
                        </div>
                    </drop-btn>
                    <btn icon="fa fa-plus" size="md" @click="setAdd" text="Add"/>
                </div>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn v-if="rowData.status==='Draft'" size="xs" class="btn-basic-b" @click="setEdit(rowData)"/>
                    <delete-btn v-if="rowData.status==='Draft'" size="xs" class="btn-basic-b"
                                @click="setDelete(rowData.id)"/>
                    <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
                </div>
            </template>

        </custom-vue-table>
        <modal no-close-on-backdrop title="Add Request" :show-additional-buttons="true" ref="addModal" width="65r">
            <template #buttons>
                <div class="btn-group">
                    <btn class="ml-1" size="sm" @click="cancelModalClicked"
                         text="Cancel"/>
                    <btn class="ml-1" :loading="loading" loading-text="Saving..." size="sm"
                         @click="SaveFunction"
                         text="Save"/>
                </div>
            </template>
            <items-add-listing @saved="savedSuccess" @failure="savedFailed" ref="addItemList" :id="id.toString()"
                              :detail="detail"
                              :productOptions="productOptions"/>
        </modal>

        <modal no-close-on-backdrop title="Edit Request" :show-additional-buttons="true" ref="editModal" width="65r">
            <template #buttons>
                <div class="btn-group">
                    <btn class="ml-1" size="sm" @click="cancelModalClicked"
                         text="Cancel"/>
                    <btn class="ml-1" size="sm" @click="saved" text="Save"/>
                    <btn :loading="loading" loading-text="Submitting..." class="ml-1" size="sm"
                         @click="editSubmitFunction" text="Submit"/>
                </div>
            </template>
            <items-edit-listing  @submit="submitSuccess" @failure="savedFailed" ref="editItemList"
                               :id="id.toString()" :detail="detail"
                               :productOption="productOptions"/>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the request. Are you
                sure
                ?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';
import ItemsAddListing from './Components/ItemsAddListing';
import ItemsEditListing from './Components/ItemsEditListing';

export default {
    name       : 'ListingComponent',
    components : { ItemsAddListing, ItemsEditListing },
    data () {
        return {
            loading        : false,
            status         : '',
            id             : '',
            detail         : [],
            productOptions : [],
            editingItem    : {},
            deletingItem   : {},
            deleteUrl      : urls.warehouseAdmin.request.delete,
            listUrl        : urls.warehouseAdmin.request.list,
            statusOptions  : [
                { value : 'Draft', label : 'Draft' },
                { value : 'PO Request', label : 'PO Request' },
                { value : 'PO Generated', label : 'PO Generated' },
                { value : 'Partial PO Generated', label : 'Partial PO Generated' },
                { value : 'Rejected', label : 'Rejected' }
            ],
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'Request ID'
                },
                {
                    name      : 'created_date',
                    sortField : 'created_date',
                    title     : 'Date'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        loadOptions () {
            const component = this;
            axios.form(urls.warehouseAdmin.request.item.productOptions, { request_id : component.id }).then(function (data) {
                const json = data.data;
                component.productOptions = json.data;
            });
        },
        viewDetails (rowData) {
            this.$router.push('/warehouse/order/request-list/' + rowData.id + '/details/');
        },
        setEdit (item) {
            this.detail = item.items;
            this.id = item.id;
            this.loadOptions();
            this.$refs.editModal.show();
        },
        setAdd (item) {
            this.id = '';
            this.loadOptions();
            this.detail = [];
            this.$refs.addModal.show();
        },
        formSuccess (response) {
            this.$refs.table.refreshTable();
            this.$refs.addModal.close();
            this.$refs.editModal.close();
        },
        itemFormSuccess (response) {
            this.detail = response.data.items.data;
        },
        setDelete (item) {
            this.deletingItem.id = item;
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = {};
            this.$refs.deleteModal.close();
            this.$refs.table.refreshTable();
        },
        SaveFunction () {
            this.loading = true;
            this.$refs.addItemList.onSaveClicked();
        },
        editSubmitFunction () {
            this.loading = true;
            this.$refs.editItemList.onSubmitClicked();
        },
        savedSuccess () {
            this.loading = false;
            this.$refs.addModal.close();
            this.$refs.table.refreshTable();
        },
        cancelModalClicked () {
            this.loading = false;
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$refs.table.refreshTable();
        },
        saved () {
            this.$notify('Saved Successfully', 'Success', {
                type : 'success'
            });
            this.$refs.editModal.close();
            this.$refs.table.refreshTable();
        },
        savedFailed () {
            this.loading = false;
        },
        submitSuccess () {
            this.$refs.editModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>

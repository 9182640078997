<template>
    <div v-if="detail">
        <s-form @submit="addProduct" ref="productAddFormModal" class="b-1 round-2">
            <div class="pl-3 pr-3 pt-3">
                <div class="row mb-3">
                    <div class="col-md-2">
                        <div class="text-5 h-form-label">Product Name</div>
                    </div>
                    <div class="col-md-8">
                        <validated-vue-select class="mb-0" name="Name" :options="options"
                                              :rules="{required:false}" v-model="product"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <div class="text-5 h-form-label">Quantity</div>
                    </div>
                    <div class="col-md-8">
                        <validated-input name="Quantity" type="number" :rules="rules.quantity"
                                         v-model="quantity"/>
                    </div>
                    <div class="col-md-2 text-right">
                        <save-btn type="submit" icon="fa fa-plus" size="sm" color="primary" text="Add Product"/>
                    </div>
                </div>
            </div>
            <custom-simple-table v-if="items.length !==0" class="table-borderless" ref="table" title="Items"
                                 :fields="fields"
                                 :data="items">
                <template slot="actions" slot-scope="props">
                    <div class="btn-group-tight">
                        <delete-btn @click="deleteItem(props.rowData)"/>
                        <edit-btn color="primary" @click="setEdit(props.rowData)"/>
                    </div>
                </template>
            </custom-simple-table>
        </s-form>
        <modal no-close-on-backdrop title="Edit Item" ref="editModal" width="30r">
            <s-form @submit="editItem" ref="productEditFormModal">
                <validated-input label="Product" hidden v-model="editingItem.product" disabled/>
                <validated-input label="Product" v-model="editingItem.name" disabled/>
                <validated-input label="Quantity" v-model="editingItem.quantity" :rules="{required : true}"
                                 type="number"/>
                <save-btn type="submit" icon="" size="sm" color="primary" text="Save"/>
            </s-form>
        </modal>
    </div>
    <div class="text-center" v-else>No data available.</div>
</template>

<script>
import urls from '../../../../../data/urls';
import axios from 'secure-axios';

export default {
    name  : 'ItemAddListing',
    props : {
        productOptions : { type : Array },
        detail         : { type : Array },
        id             : { type : String }
    },
    data () {
        return {
            DataLoading : false,
            product     : '',
            currentName : '',
            name        : '',
            quantity    : '',
            editingItem : {
                name     : '',
                product  : '',
                quantity : ''
            },
            rules : {
                quantity : {
                    min : 1
                }
            },
            saveUrl : urls.warehouseAdmin.request.saveRequestDetails,
            items   : [],
            options : [],
            fields  : [
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Product'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name       : '__slot:actions',
                    title      : '',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    watch : {
        productOptions : {
            handler () {
                this.setData();
            }
        }
    },
    mounted () {
        this.setData();
    },
    methods : {
        async onSaveClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.warehouseAdmin.request.saveRequestDetails, {
                json : JSON.stringify({ products : that.items }),
                id   : that.id
            });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Saved Successfully', 'Success', {
                    type : 'success'
                });
                that.loading = false;
                that.$emit('saved');
            } else {
                that.$emit('failure');
                that.$notify('Unable to save details..Please try again later!', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        },
        setData () {
            this.items = this.detail;
            this.options = this.productOptions;
        },
        addProduct () {
            this.setItemData();
            this.detail.push({
                id       : this.product,
                name     : this.name,
                quantity : this.quantity
            });
            this.name = '';
            this.quantity = '';
            const item = this.product;
            this.product = '';
            this.removeOption(item);
        },
        setEdit (item) {
            this.editingItem.product = item.product;
            this.editingItem.name = item.name;
            this.currentName = item.name;
            this.editingItem.quantity = item.quantity;
            this.$refs.editModal.show();
        },
        editItem () {
            for (let i = 0; i <= this.items.length; i++) {
                if (this.items[i].name === this.currentName) {
                    this.items[i].quantity = this.editingItem.quantity;
                    this.$refs.editModal.close();
                }
            }
        },
        deleteItem (item) {
            for (let i = 0; i <= this.items.length; i++) {
                if (this.items[i].id === item.id) {
                    this.items.splice(i, 1);
                    this.options.push({ value : item.id, label : item.name });
                }
            }
        },
        removeOption (item) {
            for (let i = 0; i <= this.options.length; i++) {
                if (this.options[i].value === item) {
                    this.options.splice(i, 1);
                }
            }
        },
        setItemData () {
            const productValue = this.options.filter(obj => obj.value === this.product);
            this.name = productValue[0].label;
        }
    }
};
</script>

<template>
    <div v-if="detail">
        <b-form :save-url="addUrl" ref="form" @success="itemFormSuccess" v-slot="{model, loading}"
                :save-params="{request : id, request_id: id}">
            <div class="b-1 round-2">
                <div class="p-3">
                    <div class="row mb-3">
                        <div class="col-md-2">
                            <div class="text-5 h-form-label">Product Name</div>
                        </div>
                        <div class="col-md-8">
                            <validated-vue-select class="mb-0" name="Name" :options="productOptions"
                                                  :disabled="loading"
                                                  :rules="{required:false}" v-model="model.product"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <div class="text-5 h-form-label">Quantity</div>
                        </div>
                        <div class="col-md-8">
                            <validated-input name="Quantity" type="number" :rules="{required: false}"
                                             v-model="model.quantity" :disabled="loading"/>
                        </div>
                        <div class="col-md-2 text-right">
                            <btn size="sm" icon="fa fa-plus" text="Add Product" :loading="loading"
                                 loading-text="Adding..."></btn>
                        </div>
                    </div>
                </div>
                <div>
                    <custom-simple-table class="table-borderless" ref="table" title="Items" :fields="fields"
                                         :data="items">
                        <template slot="actions" slot-scope="props">
                            <div class="btn-group-tight">
                                <delete-btn @click="setItemDelete(props.rowData)"/>
                                <edit-btn color="primary" @click="setItemEdit(props.rowData)"/>
                            </div>
                        </template>
                    </custom-simple-table>
                </div>
            </div>
        </b-form>
        <modal title="Edit Item" ref="editModal" width="30r">
            <b-form :save-url="editUrl" @success="itemSuccess" v-slot="{model, loading}"
                    :save-params="{ request_id : id }" :initial-data="editingItem">
                <validated-input label="Product" hidden v-model="model.product" disabled/>
                <validated-input label="Product" v-model="model.name" disabled/>
                <validated-input label="Quantity" v-model="model.quantity" :rules="{required : true}"
                                 type="number" :disabled="loading"/>
                <btn size="sm" text="Update" :loading="loading" loading-text="Updating..."></btn>
            </b-form>
        </modal>
        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteItemComplete">
            <p>You are about to delete the Item <b v-html="deletingItem && deletingItem.name"></b>. Are you sure
                ?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>
    </div>
    <div class="text-center" v-else>No data available.</div>
</template>

<script>
import urls from '../../../../../data/urls';
import axios from 'secure-axios';

export default {
    name  : 'ItemEditListing',
    props : {
        detail        : { type : Array },
        productOption : { type : Array },
        id            : { type : String }
    },
    data () {
        return {
            DataLoading    : false,
            productOptions : [],
            editingItem    : {},
            deletingItem   : {},
            addUrl         : urls.warehouseAdmin.request.item.add,
            editUrl        : urls.warehouseAdmin.request.item.edit,
            deleteUrl      : urls.warehouseAdmin.request.item.delete,
            items          : [],
            fields         : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Product'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name       : '__slot:actions',
                    title      : '',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    watch : {
        id : {
            handler () {
                this.setData();
            }
        },
        productOption : {
            handler () {
                this.setOptions();
            }
        }
    },
    mounted () {
        this.setData();
        this.setOptions();
    },
    methods : {
        async onSubmitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.warehouseAdmin.request.submitRequestDetails, {
                json : JSON.stringify(that.items),
                id   : that.id
            });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Submitted Successfully', 'Success', {
                    type : 'success'
                });
                that.$emit('submit');
                that.loading = false;
            } else {
                that.$notify('Unable to submit details..Please try again later!', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        },
        loadOptions () {
            const component = this;
            axios.form(urls.warehouseAdmin.request.item.productOptions, { request_id : component.id }).then(function (data) {
                const json = data.data;
                component.productOptions = json.data;
            });
        },
        setData () {
            this.items = this.detail;
        },
        setOptions () {
            this.productOptions = this.productOption;
        },
        setItemEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },
        setItemDelete (item) {
            this.deletingItem.id = item.id;
            this.deletingItem.request_id = this.id;
            this.$refs.deleteModal.show();
        },
        itemSuccess (response) {
            const refs = this.$refs;
            refs.editModal.close();
            this.items = response.data.items.data;
            this.editingItem = {};
        },
        deleteItemComplete (response) {
            this.loadOptions();
            this.deletingItem = {};
            this.$refs.deleteModal.close();
            this.items = response.data.items.data;
        },
        itemFormSuccess (response) {
            this.$refs.form.setModelValue('product', '');
            this.$refs.form.setModelValue('quantity', '');
            this.loadOptions();
            this.items = response.data.items.data;
        }
    }
};
</script>
